import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";

const createPlugin = (settings) => {
  const mergedSettings = { ...defaultSettings, ...settings };

  return {
    FormComponent: () => <></>,
    Component: (props) => <Component {...props} {...mergedSettings} />,

    name: "pizzaexpresslive/content/event-finder",
    version: "1.0.0",
    text: mergedSettings.translations.pluginName,
    description: mergedSettings.translations.pluginDescription,
    IconComponent: mergedSettings.IconComponent,

    createInitialState: () => ({}),
  };
};

export default createPlugin;
