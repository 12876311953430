import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages'

import { EmblaCarousel } from '../components/Carousel'

let Icon = () => (
    <div className="flex items-center justify-center w-full py-10">
        <FontAwesomeIcon icon={faImages} size="6x" />
    </div>
)

const CarouselComponent = ({
    state: {
        slideDuration = 5000,
        autoplay = false,
        pauseOnHover = true,
        showIndicators = false,
        indicatorsPosition = 'below',
        showArrows = true,
        fixedAspectRatio = true,
        images = [],
    },
}) => {
    const OPTIONS = { containScroll: 'trimSnaps', loop: autoplay }

    return images?.length > 0 ? (
        <EmblaCarousel
            options={OPTIONS}
            dotColor={indicatorsPosition === 'below' ? 'dark' : 'light'}
            dotPlacement={indicatorsPosition}
            hideArrows={!showArrows}
            hideDots={!showIndicators}
            autoplay={autoplay}
            autoplayDuration={slideDuration}
            pauseOnHover={pauseOnHover}
            className={indicatorsPosition === 'below' ? 'mb-6' : ''}
            slides={images?.map((image, index) => (
                <div className="flex-[0_0_100%] min-w-0 relative" key={index}>
                        <img
                            src={image?.url}
                            alt={image?.alt || ''}
                            width={0}
                            height={0}
                            sizes="100vw"
                            className={`object-cover w-full h-auto ${
                                fixedAspectRatio ? 'aspect-video' : ''
                            }`}
                        />

                </div>
            ))}
        />
    ) : <Icon/>
}

export default CarouselComponent
