import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Field } from 'formik'

export const Textarea = (props) => {
    const {
        label,
        errorMessage,
        hasError,
        required,
        hideRequiredIndicators,
        className = '',
        ...restProps
    } = props

    return (
        <div>
            {label && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`block mb-2 font-medium text-base`}
                >
                    {label}
                    {!hideRequiredIndicators && (
                        <>
                            {required ? (
                                <span className="text-brand-danger"> *</span>
                            ) : (
                                <span className="italic font-normal text-brand-black/80">
                                    {' '}
                                    &#8212; optional
                                </span>
                            )}
                        </>
                    )}
                </label>
            )}

            <textarea
                className={twMerge(
                    `block w-full text-base p-6 rounded-3xl border placeholder-opacity-50 placeholder-brand-black transition-color duration-200 focus:border-brand-primary active:border-brand-primary outline-none disabled:cursor-not-allowed ${
                        errorMessage || hasError ? 'border-brand-danger' : 'border-brand-grey'
                    } ${errorMessage ? 'mb-2' : ''}`,
                    className,
                )}
                {...restProps}
            />

            {errorMessage && (
                <p className={`text-sm mt-0 text-brand-danger block`}>{errorMessage}</p>
            )}
        </div>
    )
}

export const FormikTextarea = (props) => {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <Textarea
                    aria-label={props.label}
                    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                    isDisabled={form.isSubmitting || props.disabled}
                    value={field.value}
                    {...props}
                />
            )}
        </Field>
    )
}
