import React, { useEffect } from "react";
import { useConfig } from "@peracto/peracto-config";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import File from "@peracto/peracto-editor-ui/dist/File";
import Select from "@peracto/peracto-editor-ui/dist/Select";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import Slider from "@peracto/peracto-editor-ui/dist/Slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import startCase from "lodash/startCase";

const CarouselForm = ({
  onChange,
  state: {
    slideDuration = 5000,
    autoplay = false,
    pauseOnHover = true,
    showArrows = true,
    showIndicators = false,
    indicatorsPosition = "below",
    fixedAspectRatio = false,
    images = [],
    selectedImageIndex,
  },
}) => {
  const DEFAULT_IMAGE = {
    url: "https://via.placeholder.com/600x200",
    openInNewTab: false,
  };

  const config = useConfig();
  const { theme } = config.get("editorConfig") || [];

  const themeColours = [];

  if (theme?.colors?.brand) {
    for (const [label, colour] of Object.entries(theme.colors.brand)) {
      themeColours.push({
        label: startCase(label),
        value: colour,
      });
    }
  }

  useEffect(() => {
    return () => {
      onChange({ selectedImageIndex: 0 });
    };
  }, [onChange]);

  return (
    <>
      <Select
        label="Fixed Aspect Ratio"
        options={[
          { label: "No", value: false },
          { label: "Yes", value: true },
        ]}
        value={fixedAspectRatio}
        onChange={(e) => onChange({ fixedAspectRatio: e.value })}
      />

      <Checkbox
        label="Autoplay"
        checked={autoplay}
        onChange={(e) => onChange({ autoplay: e.target.checked })}
      />

      {autoplay && (
        <>
          <Slider
            label={`Slide Duration: ${slideDuration / 1000}s`}
            min={0}
            max={10000}
            step={100}
            value={slideDuration}
            onChange={(e) => onChange({ slideDuration: e.target.value })}
          />

          <Checkbox
            label="Pause on Hover"
            checked={pauseOnHover}
            onChange={(e) => onChange({ pauseOnHover: e.target.checked })}
          />
        </>
      )}

      <Checkbox
        label="Show Arrows"
        checked={showArrows}
        onChange={(e) => onChange({ showArrows: e.target.checked })}
      />

      <Checkbox
        label="Show Indicators"
        checked={showIndicators}
        onChange={(e) => onChange({ showIndicators: e.target.checked })}
      />

      {showIndicators && (
        <>
          <Select
            label="Indicators Position"
            options={[
              { label: "Below", value: "below" },
              { label: "Overlay", value: "overlay" },
            ]}
            value={indicatorsPosition}
            onChange={(e) => onChange({ indicatorsPosition: e.value })}
          />
        </>
      )}

      <hr />

      {selectedImageIndex !== null &&
        images &&
        images.map((image, index) => (
          <>
            <File
              label={`Image ${index + 1}`}
              onChange={(value) => {
                const imagesClone = [...images];
                imagesClone[index].url = value;
                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].url || ""}
              isInline={false}
            />

            <File
              label={`Mobile Image ${index + 1}`}
              onChange={(value) => {
                const imagesClone = [...images];
                imagesClone[index].mobileUrl = value;
                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].mobileUrl || ""}
              isInline={false}
            />

            <TextInput
              label={`Image alt text ${index + 1}`}
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].alt = e.target.value;

                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].alt || ""}
            />

            <hr />

            <TextInput
              label={`Image link ${index + 1}`}
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].link = e.target.value;

                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].link || ""}
            />

            <Checkbox
              label={`Open in new tab`}
              checked={images[index].openInNewTab}
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].openInNewTab = e.target.checked;
                onChange({
                  images: imagesClone,
                });
              }}
            />
            <button
              className="mt-2 btn btn-sm btn-danger w-100"
              onClick={(e) => {
                const imagesClone = [...images];

                imagesClone.splice(index, 1);

                onChange({
                  images: imagesClone,
                });
                onChange({
                  index: index > 0 ? index - 1 : 0,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
              {`Remove Image ${index + 1}`}
            </button>

            <hr className="my-2" />
          </>
        ))}
      <hr className="my-2" />
      <button
        className="btn btn-sm btn-secondary w-100"
        onClick={(e) => {
          const imagesClone = [...images, DEFAULT_IMAGE];
          onChange({
            images: imagesClone,
          });
          onChange({
            selectedImageIndex: imagesClone.length - 1,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add Image
      </button>
    </>
  );
};

export default CarouselForm;
