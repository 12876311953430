import React from "react";
import { defaultSettings } from "./defaults/settings";

import Component from "./Component";
import Form from "./Form/CarouselForm";

const createPlugin = (settings) => {
  const mergedSettings = { ...defaultSettings, ...settings };

  return {
    Component: (props) => <Component {...props} {...mergedSettings} />,
    FormComponent: (props) => <Form {...props} />,

    name: "peracto/content/carousel",
    version: "1.0.0",
    text: mergedSettings.translations.pluginName,
    description: mergedSettings.translations.pluginDescription,
    IconComponent: mergedSettings.IconComponent,

    createInitialState: () => ({
      label: mergedSettings.defaultLabel,
      slideDuration: 5000,
      transition: "slide",
      transitionDuration: 1000,
      autoplay: false,
      showIndicators: false,
      fixedAspectRatio: false,
      images: [],
      selectedImageIndex: 0,
    }),
  };
};

export default createPlugin;
