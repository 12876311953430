import React from "react";
import ReactSelect, { components } from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

export const Select = (props) => {
    const { className, name, label, required, options, onChange, value } =
        props;
    return (
        <div>
            {label?.length > 0 && (
                <label
                    htmlFor={name}
                    className={`mb-2 text-base block font-medium`}
                >
                    {label}{" "}
                    {required ? (
                        <span className="text-brand-danger">*</span>
                    ) : (
                        <span className="italic font-normal text-brand-black/80">
                            {" "}
                            &#8212; optional
                        </span>
                    )}
                </label>
            )}

            <div data-testid="react-select-component" className="mb-[15px]">
                <ReactSelect
                    onChange={onChange}
                    value={value}
                    options={options}
                    className={`react-select ${className}`}
                    styles={{
                        control: (provided, { isFocused, menuIsOpen }) => ({
                            ...provided,
                            padding: "0 1rem",
                            height: "3rem",
                            fontWeight: "medium",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            borderColor: "#DDDDDD",
                            borderWidth: "1px",
                            borderBottom: menuIsOpen
                                ? "none"
                                : "1px solid #DDDDDD",
                            borderRadius: menuIsOpen ? "23px 23px 0 0" : "23px",
                            color: "#101820",
                            boxShadow: isFocused ? "none" : "none",
                            fontSize: "16px",
                            "&:hover": {
                                borderColor: "#DDDDDD",
                            },
                            transition: "none",
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            height: "45px",
                            lineHeight: "30px",
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            position: "relative",
                            top: "-2px",
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            padding: "0",
                            color: "#101820",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: "calc(100% - 2px)",
                            margin: "0",
                            left: "1px",
                            right: "1px",
                            padding: "0px",
                            color: "#101820",
                            backgroundColor: "#fff",
                            border: "none",
                            borderWidth: "1px",
                            borderRadius: "0px 0px 23px 23px",
                            overflow: "hidden",
                            cursor: "pointer",
                        }),
                    }}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                        Option,
                    }}
                    data-testid="select-input"
                />
            </div>
        </div>
    );
};

const Option = ({
    innerProps,
    innerRef,
    children,
    isDisabled,
    isFocused,
    isSelected,
    ...restProps
}) => (
    <div
        ref={innerRef}
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 15px",
            fontSize: "16px",
            color: "#101820",
            opacity: isDisabled ? 0.7 : 1,
            fontWeight: 200,
            cursor: isDisabled ? "not-allowed" : "pointer",
            backgroundColor: isFocused ? "rgba(111, 128, 212, 0.2)" : "#FFFFFF",
            "&:active": {
                backgroundColor: "rgba(111, 128, 212, 0.2)",
            },
        }}
        {...innerProps}
    >
        <span>{children}</span>

        {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
);

const CaretDownIcon = () => {
    return (
        <p>
            <FontAwesomeIcon
                icon={faChevronDown}
                className="h-[14px] w-[14px] text-brand-black"
            />
        </p>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};
