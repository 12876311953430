import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";

const createPlugin = (settings) => {
  const mergedSettings = { ...defaultSettings, ...settings };

  return {
    FormComponent: () => <></>,
    Component: (props) => <Component {...props} {...mergedSettings} />,

    name: "pizzaexpresslive/content/eventsCarousel",
    version: "1.0.0",
    text: mergedSettings.translations.pluginName,
    description: mergedSettings.translations.pluginDescription,
    IconComponent: mergedSettings.IconComponent,

    createInitialState: () => ({
      numberOfEvents: 4,
      progressIndicatorStyle: "dots",
      imageFit: "cover",
      fontFamily: "",
      fontWeight: "normal",
      fontColour: "#000",
      accentColour: "#000",
      arrowColour: "#FFFFFF",
      fallbackImage: "",
      useSiloImages: false,
    }),
  };
};

export default createPlugin;
