import createPlugin from "./createPlugin";
import EventsFinderRenderer from "./Renderer/EventFinderRenderer";

const eventFinderPlugin = (settings) =>
  createPlugin({
    Renderer: EventsFinderRenderer,
    ...settings,
  });

const eventFinder = eventFinderPlugin();
export default eventFinder;

export { eventFinderPlugin, EventsFinderRenderer };
