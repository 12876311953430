import createPlugin from './createPlugin'

import CarouselRenderer from './Renderer/CarouselRenderer'

const carouselPlugin = settings =>
    createPlugin({
        Renderer: CarouselRenderer,
        ...settings,
    })

const carousel = carouselPlugin()
export default carousel

export { carouselPlugin, CarouselRenderer }
