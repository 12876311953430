import React, { forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Field } from 'formik'

const PasswordInput = ({ children, showPassword, setShowPassword }) => {
    return (
        <div className="relative w-full text-base">
            {children}
            <button
                className="absolute top-0 h-full text-sm underline right-6 text-brand-primary"
                onClick={(e) => {
                    e.preventDefault()
                    setShowPassword(!showPassword)
                }}
            >
                {showPassword ? 'Hide' : 'Show'}
            </button>
        </div>
    )
}

const InputField = ({
    errorMessage,
    hasError,
    className = '',
    ref,
    type,
    showPassword,
    hideRequiredIndicators,
    ...restProps
}) => {
    return (
        <input
            className={twMerge(
                `block h-12 w-full text-base px-6 rounded-[64px] border placeholder-opacity-50 placeholder-brand-black transition-color duration-200 focus:border-brand-primary active:border-brand-primary outline-none disabled:cursor-not-allowed ${
                    errorMessage || hasError ? 'border-brand-danger' : 'border-brand-grey'
                } ${errorMessage ? 'mb-2' : ''}`,
                className,
            )}
            ref={ref}
            type={type === 'password' && showPassword ? 'text' : type}
            {...restProps}
        />
    )
}

export const Input = forwardRef((props, ref) => {
    const { label, errorMessage, required, type, hideRequiredIndicators, ...restProps } = props

    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={type === 'hidden' && 'hidden'}>
            {label && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`block mb-2 font-medium text-base`}
                >
                    {label}
                    {!hideRequiredIndicators && (
                        <>
                            {required ? (
                                <span className="text-brand-danger"> *</span>
                            ) : (
                                <span className="italic font-normal text-brand-black/80">
                                    {' '}
                                    &#8212; optional
                                </span>
                            )}
                        </>
                    )}
                </label>
            )}

            {type === 'password' ? (
                <PasswordInput setShowPassword={setShowPassword} showPassword={showPassword}>
                    <InputField showPassword={showPassword} ref={ref} {...props} />
                </PasswordInput>
            ) : (
                <InputField showPassword={false} ref={ref} {...props} />
            )}

            {errorMessage && (
                <p className={`text-sm mt-0 text-brand-danger block`}>{errorMessage}</p>
            )}
        </div>
    )
})

export const FormikInput = (props) => {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <Input
                    aria-label={props.label}
                    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                    isDisabled={form.isSubmitting || props.disabled}
                    value={field.value}
                    {...props}
                />
            )}
        </Field>
    )
}
