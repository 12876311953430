import React from 'react'
import { Field, FieldArray } from 'formik'

import { Checkbox } from '../Checkbox'

export const CheckboxGroup = (props) => {
    return (
        <>
            {props?.label && (
                <label
                    htmlFor={props?.name || props?.id}
                    className={`block mb-2 font-medium text-base`}
                >
                    {props.label}
                    <>
                        {props.required ? (
                            <span className="text-brand-danger"> *</span>
                        ) : (
                            <span className="italic font-normal text-brand-black/80">
                                {' '}
                                &#8212; optional
                            </span>
                        )}
                    </>
                </label>
            )}
            {props?.options?.length > 0 && (
                <Field name={props.name}>
                    {({ field, form }) => (
                        <FieldArray name={field.name}>
                            {({ push, remove }) => (
                                <div className="flex flex-col">
                                    {props.options.map((option, index) => {
                                        return (
                                            <div key={index}>
                                                <Checkbox
                                                    id={option.value}
                                                    label={option.label}
                                                    aria-label={option.label}
                                                    checked={field.value.includes(option.value)}
                                                    data-value={option.value}
                                                    disabled={form.isSubmitting || props.disabled}
                                                    onChange={(e) => {
                                                        const target = e.currentTarget

                                                        if (target.checked) {
                                                            push(option.value)
                                                        } else {
                                                            const optionIdx = field.value.indexOf(
                                                                option.value,
                                                            )
                                                            remove(optionIdx)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </FieldArray>
                    )}
                </Field>
            )}
        </>
    )
}
