import React from 'react'

const EventFinderRenderer = () => {
    return (
        <div data-testid="event-finder" className="py-8 bg-brand-black">
            <div className="container justify-between space-y-6 lg:flex lg:gap-x-8 lg:items-center lg:space-y-0">
                <h2 className="mb-0 uppercase text-brand-white" data-testid="event-finder__title">
                    Find An Event
                </h2>
                <div className="space-y-6 lg:flex-1 lg:flex lg:gap-x-8 lg:items-center lg:space-y-0">
                    <input
                        required
                        placeholder="All Venues"
                        className="w-full bg-transparent border text-brand-white border-brand-white h-[45px] placeholder:text-brand-white rounded-full px-6"
                        disabled
                        data-testid="event-finder__venue"
                    />
                    <input
                        required
                        placeholder="All Months"
                        className="w-full bg-transparent border text-brand-white border-brand-white h-[45px] placeholder:text-brand-white rounded-full px-6"
                        disabled
                        data-testid="event-finder__month"
                    />
                </div>
                <a
                    href="/"
                    data-testid="event-finder__search"
                    type="button"
                    className="block"
                >
                    <button className="lg:min-w-[190px] min-w-full bg-brand-white rounded-full flex items-center justify-center leading-[45px] h-12 w-auto px-6 font-medium transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25 hover:no-underline whitespace-nowrap relative">
                        Search
                    </button>
                </a>
            </div>
        </div>
    )
}

export default EventFinderRenderer

