import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxFull } from '@fortawesome/pro-regular-svg-icons/faBoxFull'

export const defaultTranslations = {
    pluginName: 'Event Finder',
    pluginDescription: 'Displays the event finder component.',
}

export const defaultSettings = {
    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faBoxFull} size="3x" />,
}
