import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'

import { DotButton, useDotButton, PrevButton, NextButton, usePrevNextButtons } from './components'
import { twMerge } from 'tailwind-merge'


export const EmblaCarousel = (props) => {
    const {
        slides,
        options,
        dotColor = 'light',
        dotPlacement = 'below',
        hideButtons = true,
        hideArrows = false,
        hideDots = false,
        autoplay = false,
        autoplayDuration = 3000,
        pauseOnHover = true,
        className = '',
    } = props

    const [emblaRef, emblaApi] = useEmblaCarousel(
        options,
        [
            Autoplay({
                playOnInit: autoplay,
                delay: autoplayDuration,
                stopOnMouseEnter: pauseOnHover,
            }),
        ],
    )
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
        usePrevNextButtons(emblaApi)

    return (
        <div className={twMerge('relative', className)}>
            {!hideButtons && (
                <div className="absolute items-center hidden md:flex -top-20 right-2 gap-x-2">
                    <button
                        className={`h-[48px] w-[48px] text-center font-normal text-[14px] leading-[20px] border border-brand-black rounded-full text-brand-black bg-brand-light-grey`}
                        disabled={prevBtnDisabled}
                        onClick={onPrevButtonClick}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    <button
                        className={`h-[48px] w-[48px] text-center font-normal text-[14px] leading-[20px] border border-brand-black rounded-full text-brand-black bg-brand-light-grey`}
                        disabled={nextBtnDisabled}
                        onClick={onNextButtonClick}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            )}
            {!hideArrows && (
                <>
                    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </>
            )}
            <div className="relative overflow-hidden" ref={emblaRef}>
                <div className="flex touch-pan-y">{slides}</div>
            </div>

            {!hideDots && dotPlacement === 'below' && (
                <div className="flex items-center justify-center mt-8">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'h-1 flex items-center mx-[5px] transition-all rounded-full after:rounded-full after:w-full after:content-[""] after:h-1'.concat(
                                index === selectedIndex
                                    ? dotColor === 'light'
                                        ? ' after:bg-brand-white w-10'
                                        : ' after:bg-brand-black w-10'
                                    : dotColor === 'light'
                                    ? ' after:bg-brand-white/30 w-5'
                                    : ' after:bg-brand-black/30 w-5',
                            )}
                        />
                    ))}
                </div>
            )}

            {!hideDots && dotPlacement === 'overlay' && (
                <div className="absolute flex items-center justify-center -translate-x-1/2 left-1/2 bottom-6">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'h-1 flex items-center mx-[5px] transition-all rounded-full after:rounded-full after:w-full after:content-[""] after:h-1'.concat(
                                index === selectedIndex
                                    ? dotColor === 'light'
                                        ? ' after:bg-brand-white w-10'
                                        : ' after:bg-brand-black w-10'
                                    : dotColor === 'light'
                                    ? ' after:bg-brand-white/30 w-5'
                                    : ' after:bg-brand-black/30 w-5',
                            )}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
