const sharedClasses =
    'rounded-full flex items-center justify-center leading-[45px] h-12 w-auto px-6 font-medium w-auto transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25 hover:no-underline whitespace-nowrap relative'

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-secondary hover:text-brand-primary hover:bg-brand-secondary active:bg-brand-secondary active:text-brand-primary active:ring active:ring-brand-primary/40`,
    outlineClasses: `${sharedClasses} bg-none text-brand-primary border border-brand-primary hover:bg-brand-secondary hover:text-brand-primary hover:border-brand-secondary active:text-brand-primary active:border-brand-secondary active:bg-brand-secondary active:text-brand-primary active:ring active:ring-brand-primary/40`,
    linkClasses: `${sharedClasses} bg-none text-brand-primary p-0 hover:text-brand-primary`,
}

export const secondary = {
    solidClasses: `${sharedClasses} border border-brand-black bg-brand-black text-brand-white hover:text-brand-black hover:bg-transparent active:bg-transparent active:text-brand-black active:ring active:ring-brand-black-darken/40`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border border-brand-black hover:bg-brand-black hover:text-brand-white active:bg-brand-black-darken active:text-brand-white active:ring active:ring-brand-black/40`,
    linkClasses: `${sharedClasses} bg-none text-brand-black p-0 hover:text-brand-black`,
}

export const black = {
    solidClasses: `${sharedClasses} bg-brand-black text-brand-white hover:text-brand-white hover:bg-brand-black-darken active:bg-brand-black-darken active:ring active:ring-brand-black-darken/40`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border border-brand-black hover:bg-brand-black hover:text-brand-white active:bg-brand-black-darken active:text-brand-white active:ring active:ring-brand-black/40`,
    linkClasses: `${sharedClasses} bg-none text-brand-black p-0 hover:text-brand-black`,
}

export const white = {
    solidClasses: `${sharedClasses} bg-brand-white text-brand-black hover:text-brand-black hover:bg-brand-white-darken active:bg-brand-white-darken active:ring active:ring-brand-white-darken/40`,
    outlineClasses: `${sharedClasses} bg-none text-brand-white border border-brand-white hover:bg-brand-white hover:text-brand-black active:bg-brand-white-darken active:text-brand-black active:ring active:ring-brand-white/40`,
    linkClasses: `${sharedClasses} bg-none text-brand-white p-0 hover:text-brand-white`,
}
