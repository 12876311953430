import React, { useCallback, useEffect, useState } from 'react'



export const usePrevNextButtons = (
    emblaApi
)  => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback((emblaApi) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onSelect(emblaApi)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    }
}



export const PrevButton = (props) => {
    const { children, ...restProps } = props

    return (
        <button
            className="z-10 absolute left-[16px] top-[calc(50%-12px)] -translate-y-1/2 flex items-center justify-center w-8 h-8 cursor-pointer disabled:opacity-25"
            type="button"
            {...restProps}
        >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <g>
                    <path
                        d="M20.0004 26.5601L11.3071 17.8667C10.2804 16.8401 10.2804 15.1601 11.3071 14.1334L20.0004 5.44006"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
            {children}
        </button>
    )
}

export const NextButton = (props) => {
    const { children, ...restProps } = props

    return (
        <button
            className="z-10 absolute right-[16px] top-[calc(50%-12px)] -translate-y-1/2 flex items-center justify-center w-8 h-8 cursor-pointer disabled:opacity-25"
            type="button"
            {...restProps}
        >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M11.8809 26.5601L20.5742 17.8667C21.6009 16.8401 21.6009 15.1601 20.5742 14.1334L11.8809 5.44006"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            {children}
        </button>
    )
}
