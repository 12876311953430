import React from "react";

import { EventCardPlaceholder } from "./EventCardPlaceholder";

const EventsCarouselContent = () => {
  return (
    <div className="bg-brand-grey py-[32px]">
      <div className="container">
        <span className="font-heading uppercase text-[40px]">Coming Soon...</span>
        <div
          className={
            "lg:grid md:grid-cols-2 lg:grid-cols-3 space-y-8 lg:gap-8 lg:space-y-0 mt-8"
          }
        >
          <EventCardPlaceholder />
          <EventCardPlaceholder />
          <EventCardPlaceholder />
        </div>
      </div>
    </div>
  );
};

const EventsCarouselRenderer = ({ state, ...props }) => {
  return <EventsCarouselContent {...state} {...props} />;
};

export default EventsCarouselRenderer;
