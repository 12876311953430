import {
  Accordion,
  Container,
  ProductsCarousel,
  MenuItemsCarousel,
} from "@peracto/peracto-blocks-tailwind";

import EventsCarousel from "./EventsCarousel";
import EventFinder from "./EventFinder";
import Carousel from "./Carousel"

export default {
  plugins: {
    content: [
      Carousel,
      Accordion,
      ProductsCarousel,
      MenuItemsCarousel,
      EventsCarousel,
      EventFinder
    ],
    layout: [Container],
  },
};
