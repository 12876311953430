import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from 'formik'

export const Checkbox = forwardRef((props, ref) => {
    const {
        labelClassName,
        checked,
        label,
        onChange,
        disabled,
        errorMessage,
        hasError,
        className = '',
        ...restProps
    } = props

    return (
        <div className={twMerge(`${errorMessage ? 'mb-2' : ''}`, className)}>
            <label
                className={`${twMerge(
                    `inline-flex gap-x-4 items-center text-sm group ${
                        disabled ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'
                    }`,
                    labelClassName,
                )}`}
                data-testid="checkbox"
            >
                <input
                    ref={ref}
                    type="checkbox"
                    checked={checked}
                    className={`hidden`}
                    {...(onChange && { onChange })}
                    disabled={disabled}
                />
                <span
                    className={twMerge(
                        `checkbox relative block w-6 h-6 border ${
                            !checked ? 'group-hover:border-brand-primary' : ''
                        } cursor-pointer rounded ${
                            checked
                                ? 'bg-brand-black border-brand-black'
                                : 'bg-transparent border-brand-black'
                        } ${disabled && 'cursor-not-allowed'} ${
                            errorMessage || hasError ? 'border-brand-danger' : 'border-brand-black'
                        } ${className}`,
                    )}
                    {...restProps}
                >
                    {checked && (
                        <span
                            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center`}
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                className={`text-brand-white text-[13px]`}
                            />
                        </span>
                    )}
                </span>
                {label}
            </label>
            {errorMessage && (
                <p className={`text-sm mt-0 text-brand-danger block`}>{errorMessage}</p>
            )}
        </div>
    )
})

export const FormikCheckbox = (props) => {
    const { label, errorMessage, required, hasError, ...restProps } = props

    return (
        <div>
            {label?.length > 0 && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`block mb-2 font-medium text-base`}
                >
                    {label}
                    <>
                        {required ? (
                            <span className="text-brand-danger"> *</span>
                        ) : (
                            <span className="italic font-normal text-brand-black/80">
                                {' '}
                                &#8212; optional
                            </span>
                        )}
                    </>
                </label>
            )}
            <Field name={props.name}>
                {({ field, form }) => (
                    <Checkbox
                        aria-label={props.label}
                        checked={field.value}
                        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
                        isDisabled={form.isSubmitting || props.disabled}
                        {...props}
                    />
                )}
            </Field>
        </div>
    )
}
