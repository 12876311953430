const { darken, lighten } = require('polished')

// Used so hover colors get automatically updated
const brandPrimaryColor = '#6F80D4'
const brandSecondaryColor = '#161E35'
const brandBlackColor = '#1C1A1A'
const brandWhiteColor = '#FFFFFF'

const colors = {
    // Base colors
    'brand-primary': brandPrimaryColor,
    'brand-secondary': brandSecondaryColor,
    'brand-black': brandBlackColor,
    'brand-grey': '#DFDAD4',
    'brand-light-grey': '#EAE7E4',
    'brand-lighter-grey': '#F7F5F4',
    'brand-white': brandWhiteColor,
    'brand-success': '#007E19',
    'brand-light-success': '#D6FAD1',
    'brand-warning': '#906500',
    'brand-light-warning': '#FCEFC9',
    'brand-danger': '#9E1A30',
    'brand-light-danger': '#FAD9D0',
    'brand-info': '#016BAE',
    'brand-light-info': '#C9F3FA',

    // Hover colors
    'brand-primary-lighten': lighten(0.05, brandPrimaryColor),
    'brand-primary-darken': darken(0.05, brandPrimaryColor),

    'brand-secondary-lighten': lighten(0.05, brandSecondaryColor),
    'brand-secondary-darken': darken(0.05, brandSecondaryColor),

    'brand-black-lighten': lighten(0.05, brandBlackColor),
    'brand-black-darken': darken(0.05, brandBlackColor),

    'brand-white-lighten': lighten(0.05, brandWhiteColor),
    'brand-white-darken': darken(0.05, brandWhiteColor),
}

const fonts = {
    heading: ['DomaineDisplayCondensed, sans-serif'],
    body: ['DMSans, sans-serif'],
}

module.exports = { colors, fonts }
