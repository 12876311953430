import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons/faSyncAlt";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import startCase from "lodash/startCase";
import last from "lodash/last";
import uniq from "lodash/uniq";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import AsyncSelect from "react-select/async";
import { components } from "react-select";

import { Field } from "formik";
import debounce from "debounce-promise";
import classNames from "classnames";

import { sanitiseUrlValue } from "../util";

import {
    Form,
    Group,
    Input,
    Textarea,
    RichText,
    Select,
    Checkbox,
    FormActions,
    Modal,
    Tags,
} from "@peracto/peracto-ui";

import { GET_LIST, DELETE, useClient } from "@peracto/client";
import { useSettings } from "@peracto/peracto-hooks";
import { useConfig } from "@peracto/peracto-config";

import SearchEnginePreview from "./SearchEnginePreview";
import AttributeSetRenderer from "./AttributeSetRenderer";
import LocationManagement from "./LocationManagement";
import BulkPricing from "./BulkPricing";
import {
    addAttributesToFormData,
    convertObjectToOptions,
    DEFAULT_PRODUCT_AVAILABILITY_VALUES,
    DEFAULT_PRODUCT_STATUS_VALUES,
    DEFAULT_PRICE_TYPES,
    DEFAULT_DISPLAY_TYPES,
} from "../util";
import ProductListRenderer from "./ProductListRenderer";
import ProductMenuItemRelations from "./ProductMenuItemRelations";
import ProductOptions from "./ProductOptions";
import * as S from "./styled";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";
export const LIST_TYPES = {
    RELATED: "related",
    RESOURCES: "resources",
};

const CORE_FIELDS = [
    "product_name",
    "description",
    "seo_title",
    "seo_description",
];
const SALES_UNIT_OPTIONS = [
    { label: "Each", value: "each" },
    { label: "Pair", value: "pair" },
    { label: "Pack", value: "pack" },
    { label: "Roll", value: "roll" },
    { label: "Bag", value: "bag" },
    { label: "Carton", value: "carton" },
    { label: "Pallet", value: "pallet" },
    { label: "Kilo", value: "kilo" },
    { label: "Litre", value: "litre" },
    { label: "Square Metre", value: "square_metre" },
    { label: "Tonne", value: "tonne" },
    { label: "Metre", value: "metre" },
];
const LOADING_MESSAGE_DELAY = 1500;

const Option = (props) => {
    const dataProp = {
        innerProps: {
            "data-testid": props.data.value,
            ...props.innerProps,
        },
    };
    return <components.Option {...{ ...props, ...dataProp }} />;
};

const ProductForm = ({
    mode = MODE_EDIT,
    groupAttributes = [],
    values,
    onDuplicate = () => {},
    ...props
}) => {
    const config = useConfig();

    const { products } = config.get("features", {});
    const customerGroupProductLinks =
        products?.customerGroupProductLinks ?? false;
    const [customerGroups, setCustomerGroups] = useState([]);
    const customerGroupOptions = convertObjectToOptions(customerGroups, true);
    const showPricesAsCurrency = products?.showPricesAsCurrency ?? true;
    const readOnly = products?.readOnly;

    const hasAdvancedPricing =
        values &&
        (values.multipleOfSaleQuantity > 1 || values.minimumOrderQuantity > 1);

    const [isLoading, setIsLoading] = useState(true);
    const [loadingStartTime, setLoadingStartTime] = useState(0);
    const [loadingTimer, setLoadingTimer] = useState(0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [showAttributeDialog, setShowAttributeDialog] = useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] =
        useState(hasAdvancedPricing);
    const [showSEOForm, setShowSEOForm] = useState(false);
    const [attributeSetSelect, setAttributeSetSelect] = useState(true);
    const [selectedSet, setSelectedSet] = useState();
    const [formData, setFormData] = useState(values || {});
    const [groupData, setGroupData] = useState([]);
    const [redirect, setRedirect] = useState();

    const { client } = useClient();
    const { groups, values: p_values } = useSettings();

    const productPath = "whats-on";

    const AVAILABILITY_OPTIONS =
        p_values?.availability?.length > 0
            ? convertObjectToOptions(p_values?.availability)
            : DEFAULT_PRODUCT_AVAILABILITY_VALUES;

    const STATUS_OPTIONS =
        p_values?.productStatuses?.length > 0
            ? convertObjectToOptions(p_values?.productStatuses)
            : DEFAULT_PRODUCT_STATUS_VALUES;

    const additionalPriceTypes = products?.additionalPriceTypes ?? [];

    const PRICE_OPTIONS =
        p_values?.price?.length > 0
            ? uniq(p_values.price.concat(additionalPriceTypes))
            : DEFAULT_PRICE_TYPES?.concat(additionalPriceTypes);

    const DISPLAY_OPTIONS =
        p_values?.display?.length > 0
            ? p_values?.display
            : DEFAULT_DISPLAY_TYPES;

    const WEIGHT_UNIT = p_values?.weight_unit;

    const currencySymbols = products?.currencySymbols ?? {};

    const taxRateOptions = convertObjectToOptions(
        groups?.tax?.values?.tax_rates?.input?.options,
        true
    );

    const productTypeOptions = convertObjectToOptions(
        groups?.type?.values?.types?.input?.options,
        true
    );

    const defaultPriceValues = {};

    PRICE_OPTIONS.forEach((priceType) => (defaultPriceValues[priceType] = ""));

    const initialValues = {
        weight: "",
        "attribute-product_name": "",
        slug: "",
        sku: "",
        mpn: "",
        gtin: "",
        relations: {},
        resources: {},
        "resources_You Tube": "",
        status: STATUS_OPTIONS[0].value,
        availability: AVAILABILITY_OPTIONS[0].value,
        multipleOfSaleQuantity: "",
        minimumOrderQuantity: "",
        taxRate: p_values?.default_tax_rate || taxRateOptions[0]?.value || "",
        salesUnit: "each",
        unitFactor: 1,
        manageStock: false,
        customerGroups: [],
        type: productTypeOptions[0]?.value || "",
        attributeSet: "",
        ...defaultPriceValues,
        ...formData,
    };
    const resource_types =
        groups?.resources?.values?.resource_types?.input?.options;
    const relationship_types =
        groups?.relationships?.values?.relationship_types?.input?.options;

    useEffect(() => {
        if (values) {
            setFormData(values);
        }
    }, [values]);

    const fetchAttributeSets = async (inputValue) => {
        const { data } = await client(GET_LIST, "attribute-sets", {
            id: "attributes-sets",
            filter: {
                name: inputValue,
            },
        });

        const values = data.map((attr) => ({
            label: attr.name,
            value: attr["@id"],
        }));

        return values;
    };

    const debouncedfetchAttributeSets = debounce(fetchAttributeSets, 200);

    const fetchAttributeFields = async (attrSetId = "", form = null) => {
        setIsLoading(true);
        setLoadingStartTime(performance.now());

        const { data: fields } = await client(GET_LIST, "attribute-groups", {
            id: "attribute-groups",
            filter: {
                attributeSet: last(attrSetId.split("/")),
                itemsPerPage: 100000,
            },
        });

        setLoadingTimer(0);
        const formattedData = addAttributesToFormData(values, fields);

        const data = {
            ...formattedData,
            attributeSet: attrSetId,
        };

        setGroupData(fields);
        setIsLoading(false);

        if (form) {
            form.setValues({ ...initialValues, ...data });
        }
    };

    const fetchCustomerGroups = async () => {
        const { data } = await client(GET_LIST, "customer-groups", {
            id: "customer-groups",
            filter: {
                itemsPerPage: 10000,
            },
        });

        const values = {};

        data.forEach((val) => {
            values[val["@id"]] = val.name;
        });

        setCustomerGroups(values);
    };

    useEffect(() => {
        if (customerGroupProductLinks) fetchCustomerGroups();

        return;
        // eslint-disable-next-line
    }, []);

    const onDelete = async () => {
        try {
            await client(DELETE, "products", {
                id: values.id,
            });

            toast.success("Product deleted successfully!");
            setRedirect("/products");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const onChangeAttributeSet = (form, field, value) => {
        const selectedValue = value ? value : selectedSet;

        setAttributeSetSelect(false);
        setShowAttributeDialog(false);
        fetchAttributeFields(selectedValue, form);
        form.setFieldValue("attributeSet", selectedValue);
    };

    useEffect(() => {
        if (values) {
            setAttributeSetSelect(values.attributeSet === null);

            if (values.attributeSet != null) {
                fetchAttributeFields(values.attributeSet["@id"]);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let t1 = document.createAttribute("data-testid");
        t1.value = "set-dropdown";
        let attTempTest = document.getElementsByClassName(
            "attribute-temp-testid"
        )[0];
        if (attTempTest) {
            attTempTest.setAttributeNode(t1);
        }
    }, [attributeSetSelect]);

    useEffect(() => {
        let timer;
        if (isLoading && mode === MODE_EDIT) {
            timer = setInterval(
                () => setLoadingTimer(performance.now() - loadingStartTime),
                300
            );
        } else {
            timer && clearInterval(timer);
        }

        return () => {
            timer && clearInterval(timer);
        };
    }, [isLoading, loadingStartTime, mode]);

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            {mode === MODE_EDIT && !readOnly && (
                <FormActions>
                    {/* eslint-disable-next-line */}
                    <a
                        onClick={() =>
                            setAttributeSetSelect(!attributeSetSelect)
                        }
                        data-testid="toggle-change-attribute-set"
                    >
                        {attributeSetSelect ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="mr-2"
                                />
                                Cancel
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon
                                    icon={faSyncAlt}
                                    className="mr-2"
                                />
                                Change Attribute Set
                            </>
                        )}
                    </a>
                    {/* eslint-disable-next-line */}
                    <a
                        onClick={() => setShowDuplicateDialog(true)}
                        data-testid="duplicate-product"
                    >
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />
                        Duplicate
                    </a>
                    {/* eslint-disable-next-line */}
                    <a
                        className="text-danger"
                        onClick={() => setShowDeleteDialog(true)}
                        data-testid="delete-product"
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Delete Product
                    </a>
                </FormActions>
            )}

            <S.LoadingMessage
                className={classNames({
                    "display-message": loadingTimer > LOADING_MESSAGE_DELAY,
                })}
            >
                <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
                Fetching Product Attribute data...
            </S.LoadingMessage>

            <Form
                autoComplete="off"
                {...props}
                values={initialValues}
                checkbox={{
                    text: "Index Product",
                    name: "saveAndIndex",
                    checked: true,
                }}
                onReset={() => {
                    if (mode === MODE_ADD) {
                        setAttributeSetSelect(true);
                        setGroupData([]);
                    }
                    setShowAdvancedOptions(hasAdvancedPricing);
                    setShowSEOForm(false);
                }}
            >
                {attributeSetSelect && (
                    <S.AttributeSetSelect data-testid="attributes">
                        <div className="attribute-select--icon d-none d-lg-block">
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size="2x"
                            />
                        </div>

                        <div className="attribute-select--input">
                            <div className="flex-wrap d-flex justify-content-between align-items-start flex-lg-nowrap">
                                <p className="mb-2">
                                    Please select the attribute set that this
                                    product belongs to:
                                </p>

                                <Link
                                    to="/attribute-sets"
                                    data-testid="manage-sets"
                                >
                                    Manage sets
                                </Link>
                            </div>
                            <Field name="attributeSet">
                                {({ field, form }) => {
                                    return (
                                        <>
                                            <AsyncSelect
                                                components={{ Option }}
                                                className="w-100 attribute-temp-testid"
                                                classNamePrefix="list"
                                                loadOptions={(input) =>
                                                    debouncedfetchAttributeSets(
                                                        input,
                                                        field.value
                                                    )
                                                }
                                                isSearchable={true}
                                                defaultOptions={true}
                                                onChange={(option) => {
                                                    if (option.value) {
                                                        setSelectedSet(
                                                            option.value
                                                        );
                                                        if (
                                                            values &&
                                                            values.attrSetId !==
                                                                null
                                                        ) {
                                                            setShowAttributeDialog(
                                                                true
                                                            );
                                                        } else {
                                                            onChangeAttributeSet(
                                                                form,
                                                                field.name,
                                                                option.value
                                                            );
                                                        }
                                                    }
                                                }}
                                                placeholder="Search for an Attribute Set..."
                                                noOptionsMessage={({
                                                    inputValue,
                                                }) => {
                                                    if (inputValue.length > 0) {
                                                        return `No results found for '${inputValue}'.`;
                                                    } else {
                                                        return "Enter text to begin searching.";
                                                    }
                                                }}
                                            />

                                            <Modal
                                                isVisible={showAttributeDialog}
                                                title="Change Attribute Set"
                                                close={() =>
                                                    setShowAttributeDialog(
                                                        false
                                                    )
                                                }
                                                buttons={[
                                                    {
                                                        type: "btn-outline-secondary",
                                                        text: "Close",
                                                        action: () => {
                                                            setAttributeSetSelect(
                                                                false
                                                            );
                                                            setShowAttributeDialog(
                                                                false
                                                            );
                                                        },
                                                    },
                                                    {
                                                        type: "btn-success",
                                                        text: "Change Set",
                                                        action: () => {
                                                            onChangeAttributeSet(
                                                                form,
                                                                field.name,
                                                                selectedSet
                                                            );
                                                        },
                                                    },
                                                ]}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    size="4x"
                                                    className="mb-4 d-block"
                                                />
                                                Are you sure you would like to
                                                change the attribute set? Any
                                                existing values for attributes
                                                that are not present in the new
                                                set will be deleted.
                                            </Modal>
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                    </S.AttributeSetSelect>
                )}
                <div data-testid="core-section">
                    <Group key="core" id="core" name="Core">
                        <Field name="attribute-product_name">
                            {({ field, form }) => {
                                return (
                                    <Input
                                        name={field.name}
                                        label="Product Name"
                                        required
                                        onBlur={() => {
                                            if (field.value?.length > 0) {
                                                if (
                                                    !form.values.slug ||
                                                    form.values.slug.length ===
                                                        0
                                                ) {
                                                    form.setFieldValue(
                                                        "slug",
                                                        sanitiseUrlValue(
                                                            form.values?.[
                                                                "attribute-product_name"
                                                            ]
                                                        )
                                                    );
                                                }

                                                if (
                                                    !form.values.seo_title ||
                                                    form.values.seo_title
                                                        .length === 0
                                                ) {
                                                    form.setFieldValue(
                                                        "attribute-seo_title",
                                                        field.value
                                                    );
                                                }
                                            }
                                        }}
                                        disabled={
                                            attributeSetSelect ||
                                            isLoading ||
                                            readOnly
                                        }
                                        testId="product-name"
                                    />
                                );
                            }}
                        </Field>

                        <RichText
                            name="attribute-description"
                            label="Description"
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                        />

                        <Input
                            name="weight"
                            label="Weight"
                            type="number"
                            min="0"
                            suffix={WEIGHT_UNIT}
                            testId="weight"
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                        />

                        <Select
                            name="type"
                            placeholder="Select Product Type..."
                            options={productTypeOptions}
                            label="Product Type"
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                            testId="product-type-dropdown"
                            testIdItems="product-type-dropdown__item"
                            testIdIndex={2}
                        />

                        {customerGroupProductLinks && (
                            <Tags
                                name="customerGroups"
                                placeholder="Select Customer Group(s)..."
                                options={customerGroupOptions}
                                label="Customer Group(s)"
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                                testId="customer-group-dropdown"
                                testIdItems="customer-group-dropdown__item"
                            />
                        )}
                    </Group>
                </div>

                <div data-testid="availability">
                    <Group
                        key="availability-and-display"
                        id="availability-and-display"
                        name="Availability and Display"
                    >
                        <Select
                            name="status"
                            options={STATUS_OPTIONS}
                            defaultValue={STATUS_OPTIONS[0]}
                            label="Product Status"
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                            testId="product-status-dropdown"
                            testIdItems="product-status-dropdown__item"
                            testIdIndex={0}
                        />

                        <Select
                            name="availability"
                            options={AVAILABILITY_OPTIONS}
                            defaultValue={AVAILABILITY_OPTIONS[0]}
                            label="Availability"
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                            testId="product-availability-dropdown"
                            testIdItems="product-status-availability__item"
                            testIdIndex={1}
                        />

                        <label>Display</label>

                        {DISPLAY_OPTIONS.map((option) => (
                            <Checkbox
                                key={`display-${option}`}
                                name={option}
                                label={`Exclude from ${startCase(
                                    option.replace(/_/g, " ")
                                )}`}
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                            />
                        ))}
                    </Group>
                </div>

                <div data-testid="inventory">
                    <Group key="inventory" id="inventory" name="Inventory">
                        <div className="form-row">
                            <div className="col-12 col-lg-4">
                                <Field name="sku">
                                    {({ field, form }) => {
                                        return (
                                            <Input
                                                name={field.name}
                                                label="SKU"
                                                help="Stock Keeping Unit"
                                                required
                                                onBlur={() => {
                                                    if (
                                                        field.value?.length > 0
                                                    ) {
                                                        const productSlug =
                                                            form.values?.[
                                                                "attribute-product_name"
                                                            ]?.length > 0
                                                                ? sanitiseUrlValue(
                                                                      form
                                                                          .values?.[
                                                                          "attribute-product_name"
                                                                      ]
                                                                  )
                                                                : "";

                                                        const productSku =
                                                            sanitiseUrlValue(
                                                                field.value
                                                            );

                                                        if (
                                                            form.values.slug ===
                                                            productSlug
                                                        ) {
                                                            const slug = `${productSlug}-${productSku}`;
                                                            form.setFieldValue(
                                                                "slug",
                                                                slug
                                                            );
                                                        }
                                                    }
                                                }}
                                                disabled={
                                                    attributeSetSelect ||
                                                    isLoading ||
                                                    readOnly
                                                }
                                                testId="sku"
                                            />
                                        );
                                    }}
                                </Field>
                            </div>

                            <div className="col-12 col-lg-4">
                                <Input
                                    name="mpn"
                                    label="MPN"
                                    help="Manufacturer part number"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    testId="mpn"
                                />
                            </div>

                            <div className="col-12 col-lg-4">
                                <Input
                                    name="gtin"
                                    label="GTIN"
                                    help="Global Trade Item Number"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    testId="gtin"
                                />
                            </div>

                            <div className="col-12 col-lg-4">
                                <Select
                                    name="salesUnit"
                                    label="Sales Unit"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    options={SALES_UNIT_OPTIONS}
                                    testId="sales-unit"
                                />
                            </div>

                            <div className="col-12 col-lg-4">
                                <Input
                                    name="unitFactor"
                                    type="number"
                                    min="1"
                                    label="Unit Factor"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    testId="unit-factor"
                                    tooltip="Set a Unit Factor to divide the inventory count by a specified number.
                                        For example, if your inventory is in units, but you sell in 10s you would set the value to '10'."
                                />
                            </div>
                        </div>

                        <hr />

                        <div data-testid="track-quantity">
                            <Checkbox
                                name="manageStock"
                                label="Track Quantity"
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                            />
                        </div>

                        <LocationManagement
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                        />
                    </Group>
                </div>

                <div data-testid="prices">
                    <Group key="pricing" id="pricing" name="Pricing">
                        <div className="form-row">
                            {PRICE_OPTIONS.map((option) => {
                                const label = (
                                    products?.labels?.[option] ||
                                    startCase(option)
                                )?.replace("Rrp", "RRP");

                                return (
                                    <div
                                        className="col-12 col-lg-3"
                                        key={`price-option-${option}`}
                                    >
                                        <Input
                                            name={option}
                                            type="number"
                                            min={0}
                                            step={0.01}
                                            label={
                                                option === "rrp"
                                                    ? products?.showRRPAsWasPrice
                                                        ? products?.labels?.[
                                                              option
                                                          ] || "Was"
                                                        : products?.labels?.[
                                                              option
                                                          ] || "RRP"
                                                    : label
                                            }
                                            placeholder={
                                                option === "rrp"
                                                    ? products?.showRRPAsWasPrice
                                                        ? products?.labels?.[
                                                              option
                                                          ] || "Was Price"
                                                        : products?.labels?.[
                                                              option
                                                          ] || "RRP Price"
                                                    : `${label}`
                                            }
                                            disabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            prefix={
                                                showPricesAsCurrency
                                                    ? currencySymbols?.[option]
                                                        ? currencySymbols[
                                                              option
                                                          ]
                                                        : "\u00A3"
                                                    : null
                                            }
                                            testId={option}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        {products?.bulkPricing && (
                            <BulkPricing
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                            />
                        )}

                        <div className="form-row">
                            <div className="col-12 col-lg-4">
                                <Select
                                    name="taxRate"
                                    placeholder="Select Tax Rate..."
                                    options={taxRateOptions}
                                    label="Tax rate"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    testId="tax-rate-dropdown"
                                    testIdItems="tax-rate-dropdown__item"
                                    testIdIndex={2}
                                />
                            </div>

                            <div className="col-12 col-lg-8">
                                <div className="d-flex justify-content-end align-items-end h-100">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setShowAdvancedOptions(
                                                !showAdvancedOptions
                                            )
                                        }
                                        className="px-0 btn btn-link"
                                        data-testid="toggle-advanced-options"
                                    >
                                        {showAdvancedOptions ? "Hide" : "Show"}{" "}
                                        advanced options
                                    </button>
                                </div>
                            </div>
                        </div>

                        {showAdvancedOptions > 0 && (
                            <>
                                <div className="form-row">
                                    <div className="col-12 col-lg-4">
                                        <Input
                                            name="multipleOfSaleQuantity"
                                            label="Multiple of Sale Quantity"
                                            disabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            testId="multiple-of-sale-quantity"
                                        />
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <Input
                                            name="minimumOrderQuantity"
                                            label="Minimum Order Quantity"
                                            disabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            testId="minimum-order-quantity"
                                        />
                                    </div>
                                </div>
                                {products?.maxQuantity && (
                                    <div className="form-row">
                                        <div className="col-12 col-lg-4">
                                            <Input
                                                name="maximumOrderQuantity"
                                                label="Maximum Order Quantity"
                                                disabled={
                                                    attributeSetSelect ||
                                                    isLoading ||
                                                    readOnly
                                                }
                                                testId="maximum-order-quantity"
                                            />
                                        </div>

                                        <div className="col-12 col-lg-4">
                                            <Input
                                                name="orderQuantityMessage"
                                                label="Order Quantity Message"
                                                disabled={
                                                    attributeSetSelect ||
                                                    isLoading ||
                                                    readOnly
                                                }
                                                testId="order-quantity-message"
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </Group>
                </div>

                <div data-testid="product-resources-section">
                    <Group
                        key="product-resources"
                        id="product-resources"
                        name="Product Resources"
                    >
                        <ProductListRenderer
                            groups={resource_types}
                            type={LIST_TYPES.RESOURCES}
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                        />
                    </Group>
                </div>

                <div data-testid="seo">
                    <Group
                        key="search-engine-preview"
                        id="search-engine-preview"
                        name="Search Engine Listing Preview"
                    >
                        <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="px-0 btn btn-link"
                                onClick={() => setShowSEOForm(!showSEOForm)}
                                data-testid="toggle-edit-close"
                            >
                                {showSEOForm ? "Close" : "Edit"}
                            </button>
                        </div>
                        {showSEOForm ? (
                            <>
                                <Field name="slug">
                                    {({ field, form }) => (
                                        <Input
                                            name={field.name}
                                            label="URL & Path"
                                            prefix={`${
                                                process.env
                                                    .REACT_APP_STOREFRONT_URL ||
                                                window.location.origin
                                            }/${productPath}/`}
                                            disabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            onBlur={() => {
                                                if (field.value?.length > 0) {
                                                    const slug = encodeURI(
                                                        field.value
                                                            .split(" ")
                                                            .join("-")
                                                            .replace(/-+/g, "-")
                                                            .toLowerCase()
                                                    );

                                                    form.setFieldValue(
                                                        field.name,
                                                        slug
                                                    );
                                                }
                                            }}
                                            testId="seo-path-slug"
                                        />
                                    )}
                                </Field>

                                <Input
                                    name="attribute-seo_title"
                                    label="Search Engine Title"
                                    disabled={
                                        attributeSetSelect ||
                                        isLoading ||
                                        readOnly
                                    }
                                    testId="seo-title"
                                />

                                <Field name="attribute-seo_description">
                                    {({ field }) => (
                                        <Textarea
                                            name={field.name}
                                            label="Search Engine Description"
                                            disabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            charactercount={
                                                field?.value?.length || 0
                                            }
                                            testId="seo-description"
                                        />
                                    )}
                                </Field>
                            </>
                        ) : (
                            <SearchEnginePreview path={productPath} />
                        )}
                    </Group>
                </div>

                <div data-testid="relationships">
                    <Group
                        key="related-products"
                        id="related-products"
                        name="Related Products"
                    >
                        <ProductListRenderer
                            currentProduct={formData?.id ? formData.id : null}
                            groups={relationship_types}
                            type={LIST_TYPES.RELATED}
                            disabled={
                                attributeSetSelect || isLoading || readOnly
                            }
                        />
                    </Group>
                </div>

                {products?.productMenuItemRelations && (
                    <div data-testid="product-menu-item-relations">
                        <Group
                            key="product-menu-item-relations"
                            id="product-menu-item-relations"
                            name="Product Menu Item Relations"
                        >
                            <ProductMenuItemRelations
                                setRedirect={setRedirect}
                                onSubmit={props.onSubmit}
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                            />
                        </Group>
                    </div>
                )}

                {!!products?.productVariants && mode === MODE_EDIT && (
                    <div data-testid="variants">
                        <Group key="variants" id="variants" name="Variants">
                            <ProductOptions
                                setRedirect={setRedirect}
                                onSubmit={props.onSubmit}
                                disabled={
                                    attributeSetSelect || isLoading || readOnly
                                }
                            />
                        </Group>
                    </div>
                )}

                <div data-testid="additional-attributes">
                    {groupData.length > 0 && (
                        <>
                            {groupData.map((group) => {
                                if (
                                    /* Don't render the group if it only contains items in the core fields array */
                                    group.attributes.every((attr) =>
                                        CORE_FIELDS.includes(attr.code)
                                    )
                                ) {
                                    return false;
                                }

                                return (
                                    <Group
                                        key={group.id}
                                        id={group.id}
                                        name={group.name}
                                    >
                                        <AttributeSetRenderer
                                            formData={group.attributes}
                                            isDisabled={
                                                attributeSetSelect ||
                                                isLoading ||
                                                readOnly
                                            }
                                            coreFields={CORE_FIELDS}
                                        />
                                    </Group>
                                );
                            })}
                        </>
                    )}
                </div>
            </Form>

            {mode === MODE_EDIT && (
                <>
                    <Modal
                        isVisible={showDeleteDialog}
                        title="Delete Product"
                        close={() => setShowDeleteDialog(false)}
                        buttons={[
                            {
                                type: "btn-outline-secondary",
                                text: "Close",
                                action: () => setShowDeleteDialog(false),
                            },
                            {
                                type: "btn-danger",
                                text: "Delete Product",
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="mb-4 d-block"
                        />
                        Are you sure you would like to permanently delete this
                        product?
                    </Modal>

                    <Modal
                        isVisible={showDuplicateDialog}
                        title="Duplicate Product"
                        close={() => setShowDuplicateDialog(false)}
                        buttons={[
                            {
                                type: "btn-outline-secondary",
                                text: "Close",
                                action: () => setShowDuplicateDialog(false),
                            },
                            {
                                type: "btn-success",
                                text: "Duplicate Product",
                                action: () => {
                                    onDuplicate(values);
                                    setShowDuplicateDialog(false);
                                },
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="4x"
                            className="mb-4 d-block"
                        />
                        Are you sure you would like to duplicate this product?
                    </Modal>
                </>
            )}
        </>
    );
};

ProductForm.displayName = "ProductForm";

ProductForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProductForm;
