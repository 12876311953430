import React from "react";

export const EventCardPlaceholder = () => {
  return (
    <div className="bg-brand-white rounded-[32px] p-4 md:p-6 min-h-full">
      <div className="text-center">
        <p className="text-sm md:text-lg uppercase mb-6">DATE OF EVENT</p>

        <div className="relative mb-6">
          <div className="min-w-0 h-[245px] w-full">
            <img
              src="/images/placeholder.jpg"
              alt="Events pic"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <p className="font-heading-small text-base sm:text-lg lg:text-xl mb-4">
          Event Name
        </p>

        <p className="font-body mb-4 text-base">Venue Name</p>

        <p className="font-heading-small text-lg mb-4">Price</p>

        <div className="min-h-[24px] mb-4">
          <div className="flex justify-center items-center ">
            <p className="text-base">Doors AM/PM</p>
            <div className="mx-2 w-[1px] h-[16px] bg-brand-black-25" />
            <p className="text-base">Show AM/PM</p>
          </div>
        </div>
        <div className={"flex gap-4"}>
          <button className="w-full rounded-full leading-[45px] h-12 px-6 font-medium relative bg-brand-primary text-brand-secondary hover:text-brand-primary hover:bg-brand-secondary">
            Book now
          </button>

          <button className="w-full rounded-full leading-[45px] h-12 px-6 font-medium relative bg-none text-brand-black border border-brand-black hover:bg-brand-black hover:text-brand-white">
            Read more
          </button>
        </div>
      </div>
    </div>
  );
};
