import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            primary: "#6F80D4",
            secondary: "#161E35",
            black: "#1C1A1A",
            grey: "#DFDAD4",
            lightGrey: "#EAE7E4",
            lighterGrey: "#F7F5F4",
            white: "#FFFFFF",
            success: "#007E19",
            lightSuccess: "#D6FAD1",
            warning: "#906500",
            lightWarning: "#FCEFC9",
            danger: "#9E1A30",
            lightDanger: "#FAD9D0",
            info: "#016BAE",
            lightInfo: "#C9F3FA",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "DomaineDisplayCondensed, sans-serif",
        body: "DMSans, sans-serif",
    },
    borderRadius: {
        default: "4px",
        lg: "8px",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const styles = css`
    h1 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 72px;
        line-height: 76px;
        margin-bottom: 24px;

        &.heading1-huge {
            font-size: 88px;
            line-height: 94px;
        }
    }

    h2 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 24px;
    }

    h3 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    h4 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
    }

    h5 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
    }

    h6 {
        font-family: ${EditorTheme.fonts.heading};
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    p {
        font-family: ${EditorTheme.fonts.body};
        font-size: 16px;
        line-height: 24px;
    }

    a:not([type^="button"]) {
        color: ${EditorTheme.colors.brand.primary};
        text-decoration: underline;

        &:hover {
            color: ${EditorTheme.colors.brand.secondary};
        }
    }

    /* LISTS */
    ul:not([class^='DayPicker']) {
        list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='-1 -1 2 2'><circle r='1' fill='%231C1A1A' /></svg>");;
        list-style-position: inside;

        li {
            margin-bottom: 16px;

            &:before {
                content: '';
                width: 12px;
                display: inline-block;
            }
        }
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;

        li {
            margin-bottom: 16px;

            &:before {
                content: '';
                width: 12px;
                display: inline-block;
            }
        }
    }

    /* BLOCKQUOTE */
    blockquote {
        border-left: 4px solid ${EditorTheme.colors.brand.primary} !important;
        color: ${EditorTheme.colors.brand.black};
        padding-left: 32px;
        font-style: normal !important;
    }

    /* HORZIONTAL RULE */
    hr {
        border: 0;
        border-top: 1px solid ${EditorTheme.colors.brand.grey};
        margin: 32px 0;
    }

    /* TABLE */
    table,
    .table {
        width: 100%;
        border-collapse: collapse;
        border-radius: ${EditorTheme.borderRadius.default};

        td {
            padding: 12px 16px !important;

            &:first-child {
                border-top-left-radius: ${EditorTheme.borderRadius.lg};
                border-bottom-left-radius: ${EditorTheme.borderRadius.lg};
            }

            &:last-child {
                border-top-right-radius: ${EditorTheme.borderRadius.lg};
                border-bottom-right-radius: ${EditorTheme.borderRadius.lg};
            }
        }

        tr:nth-child(odd) {
            td {
                background: ${EditorTheme.colors.brand.grey};
            }
        }

        tr:nth-child(1) {
            td {
                background: ${EditorTheme.colors.brand.secondary};
                color: ${EditorTheme.colors.brand.white};
                font-weight: 700;
            }
        }
    }

    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }

    /* ADMIN CONTENT BLOCK */
    .ory-row {
        .content__form form {
            display: flex;
            flex-direction: column;
            row-gap: 24px;

            h3 {
                margin-bottom: 0;
            }

            button {
                align-self: flex-start;
            }
        }

        select {
            border-radius: 32px;
        }
    }
`;

export const GlobalStyles = css`
    @font-face {
        font-family: "DMSans";
        src: url("/fonts/DMSans-Regular.ttf");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "DMSans";
        src: url("/fonts/DMSans-Medium.ttf");
        font-weight: 500;
        font-style: medium;
    }

    @font-face {
        font-family: "DMSans";
        src: url("/fonts/DMSans-Bold.ttf");
        font-weight: 700;
        font-style: bold;
    }

    @font-face {
        font-family: "DomaineDisplay";
        src: url("/fonts/DomaineDisplay-Regular.ttf");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "DomaineDisplayCondensed";
        src: url("/fonts/DomaineDisplayCondensed-Regular.ttf");
        font-weight: 400;
        font-style: normal;
    }

    ${styles}
`;
