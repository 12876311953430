import createPlugin from "./createPlugin";
import EventsCarouselRenderer from "./Renderer/EventsCarouselRenderer";

const eventsCarouselPlugin = (settings) =>
  createPlugin({
    Renderer: EventsCarouselRenderer,
    ...settings,
  });

const eventsCarousel = eventsCarouselPlugin();
export default eventsCarousel;

export { eventsCarouselPlugin, EventsCarouselRenderer };
